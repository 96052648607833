import React, { useState } from 'react'
import { Menu } from 'antd'
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons'
import NAV from './Navigation'
const { SubMenu } = Menu

// submenu keys of first level
const rootSubmenuKeys = NAV.map(n=>n.key)

const LeadVisaMobileNavMenu = () => {
  // Prevent window scroll while mobile nav menu is open
  const [openKeys, setOpenKeys] = React.useState(['sub1'])

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  return (
    <div className="lead-mobile-nav">
      <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} style={{ width: '100%' }}>
        {NAV.map(nav => {
          if (nav?.childrens) {
            return (
                <SubMenu key={nav.key} title={nav.label} >
                  {nav.childrens.map(ch => {
                    return (
                        <Menu.Item key={ch.label}>
                          <a href={ch.link}>{ch.label}</a>
                        </Menu.Item>
                    )
                  })}
                </SubMenu>
            )
          }
          return (
              <Menu.Item key={nav.label}>
                <a href={nav.link}>{nav.label}</a>
              </Menu.Item>
          )
        })}
      </Menu>
    </div>
  )
}

export default LeadVisaMobileNavMenu
