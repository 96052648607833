import React from 'react'
import { Link } from 'gatsby'
import { MdMenu } from 'react-icons/md'
import Image from '../image'
import {Menu} from "antd";
import VIPNAV from "./Navigation";
import './VipNavBar.less';
const { SubMenu } = Menu
const VipNavBar = ({ navbar, setMobileMenuIsShown }) => {
  return (
      <>
        <nav className="st-navbar vip-navbar border-gray-200 border-b-2 sm:py-2">
          <div  className="header-topbar md-hidden sm-hidden clearfix">
            <div className="container">
              <div className="row">
                <div className="col-md-12">

                  <ul className="info-list fleft">
                    <li>
                      <i className="fa fa-map-marker"></i>  230 Murray St, Hobart TAS 7000 ( <a
                        href="https://goo.gl/maps/fcfvGhdchv3Cppar9" target="_blank">View
                      map</a> )
                    </li>
                    <li>
                      <i className="fa fa-phone"></i> <a href="tel:0449 845 797">0449 845 797</a></li>
                    <li>
                      <i className="fa fa-clock"></i> Everyday 07:00 - 19:00
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
          <div className="nav-items flex flex-row items-center justify-between p-2">
            {/* Content aligned to the left */}
            <div className="flex flex-row items-center w-100">
              <Link to="/">
                <Image media={navbar?.logo} className="st-logo-header h-8 w-auto object-contain" />
              </Link>
              {/* List of links on desktop */}
              <div className="vip-menu-item sm:hidden w-100">
                <Menu mode="horizontal">
                  {VIPNAV.map(nav => {
                    if (nav?.childrens) {
                      return (
                          <SubMenu key={nav.key} title={nav.label} >
                            {nav.childrens.map(ch => {
                              return (
                                  <Menu.Item key={ch.label}>
                                    <a href={ch.link}>{ch.label}</a>
                                  </Menu.Item>
                              )
                            })}
                          </SubMenu>
                      )
                    }
                    return (
                        <Menu.Item key={nav.label}>
                          <a href={nav.link}>{nav.label}</a>
                        </Menu.Item>
                    )
                  })}
                </Menu>
              </div>
            </div>
            {/* Hamburger menu on mobile */}
            <button onClick={() => setMobileMenuIsShown(true)} className="p-1 block md:hidden">
              <MdMenu className="h-8 w-auto" />
            </button>
          </div>
        </nav>
      </>
  )
}



export default VipNavBar
