export const VIPNAV = [
  {
    key: 'home',
    label: 'Home',
    link: '/'
  },
  {
    key: 'lesson-packages',
    label: 'Lessons',
    link: '/lesson-packages'
  },
  // {
  //   key: 'rehab-training',
  //   label: 'Rehab Training',
  //   link: '/rehab'
  // },
  {
    key: 'keys2drive',
    label: 'Keys2Drive',
    link: '/keys2drive'
  },
  {
    key: 'p1assessment',
    label: 'P1 Assessment',
    link: '/p1-assessment'
  },
  // {
  //   key: 'booking',
  //   label: 'Booking',
  //   childrens: [
  //     {
  //       label: 'Lesson Packages',
  //       link: '/lesson-packages'
  //     },
  //
  //   ]
  // },

  {
    key: 'about',
    label: 'About',
    link: '/about'
  },

  {
    key: 'ContactUS',
    label: 'Contact US',
    link: '/contact-us'
  }
]

export default VIPNAV
