export const NAV = [
  {
    key: 'home',
    label: 'Home',
    link: '/'
  },
  {
    key: 'about',
    label: 'About',
    childrens: [
      {
        label: 'About Leader Education Visa Services',
        link: '/about-levs'
      },
      {
        label: 'Message From Director',
        link: '/message-from-director'
      },
      // {
      //   label: 'Our Team',
      //   link: '/our-team-2'
      // },
      {
        label: 'Corporate Social Responsibility',
        link: '/corporate-social-responsibility'
      }
    ]
  },
  {
    key: 'StudentServices',
    label: 'Student Services',
    childrens: [
      {
        label: 'Education Overview',
        link: '/education-services'
      },
      {
        label: 'Career Counselling',
        link: '/career-counselling'
      },
      {
        label: 'Immigration Counselling',
        link: '/immigration-counselling'
      },
      {
        label: 'Documentation Assistance',
        link: '/documentation-assistance'
      },
      {
        label: 'University and College admissions',
        link: '/university-and-college-admissions'
      },
      {
        label: 'College Fees Payment Assistance',
        link: '/college-fees-payment-assistance'
      }
    ]
  },
  {
    key: 'Migration Services',
    label: 'MigrationServices',
    childrens: [
      {
        label: 'Migration Overview',
        link: '/migration-overview'
      },
      {
        label: 'Skilled Graduate Visa',
        link: '/skilled-graduate-visa'
      },
      {
        label: 'General Skilled Migration',
        link: '/general-skilled-migration'
      },
      {
        label: 'Employer-Sponsored Visas',
        link: '/employer-sponsored-visas'
      },
      {
        label: 'Student Visa',
        link: '/student-visa'
      },
      {
        label: 'Partner Visa',
        link: '/partner-visa'
      },
      {
        label: 'Visitor Visa',
        link: '/visitor-visa'
      }
    ]
  },
  {
    key: 'OtherServices',
    label: 'Other Services',
    childrens: [
      {
        label: 'Professional Year Course',
        link: '/professional-year-course'
      },
      {
        label: 'NAATI Credentialed Community Language (CCL) Test',
        link: '/naati-credentialed-community-language-ccl-test/'
      },
      {
        label: 'OSHC OVHC',
        link: '/oshc-ovhc'
      },
      {
        label: 'TAX Return',
        link: '/tax-return'
      }
    ]
  },
  {
    key: 'ContactUS',
    label: 'Contact US',
    link: '/contact-us'
  }
]

export default NAV
