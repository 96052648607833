import React from 'react'
import { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { MdMenu } from 'react-icons/md'
import MobileNavMenu from './mobile-nav-menu'
import ButtonLink from './button-link'
import Image from './image'
import { mediaPropTypes, linkPropTypes, buttonLinkPropTypes } from '@/utils/types'
import { getButtonAppearance } from '@/utils/button'
import CustomLink from './custom-link'
import LeadVisaNavBar from './LeadVisa/LeadVisaNavBar'
import VipNavBar from './VipDrivingHobart/VipNavBar'
import './Navbar.less'
const BaseNav = ({ navbar, setMobileMenuIsShown }) => {
  const environment = process.env.GATSBY_STRAPI_ENV

  if (environment == 'leadvisa') {
    return <LeadVisaNavBar navbar={navbar} setMobileMenuIsShown={setMobileMenuIsShown} />
  }

  if (environment == 'vipdrivinghobart') {
    return <VipNavBar navbar={navbar} setMobileMenuIsShown={setMobileMenuIsShown} />
  }

  return (
    <>
      <nav className="st-navbar border-gray-200 border-b-2 py-6 sm:py-2">
        <div className="container flex flex-row items-center justify-between">
          {/* Content aligned to the left */}
          <div className="flex flex-row items-center">
            <Link to="/">
              <Image media={navbar?.logo} className="st-logo-header h-8 w-auto object-contain" />
            </Link>
            {/* List of links on desktop */}
            <ul className="hidden list-none md:flex flex-row gap-4 items-baseline ml-10">
              {navbar?.links.map(navLink => (
                <li key={navLink.id}>
                  <CustomLink link={navLink}>
                    <div className="hover:text-gray-900 px-2 py-1">{navLink.text}</div>
                  </CustomLink>
                </li>
              ))}
            </ul>
          </div>
          {/* Hamburger menu on mobile */}
          <button onClick={() => setMobileMenuIsShown(true)} className="p-1 block md:hidden">
            <MdMenu className="h-8 w-auto" />
          </button>
          {/* CTA button on desktop */}
          {navbar?.button && (
            <div className="hidden md:block">
              <ButtonLink
                button={navbar.button}
                appearance={getButtonAppearance(navbar.button.type, 'light')}
                compact
              />
            </div>
          )}
        </div>
      </nav>
    </>
  )
}

const Navbar = ({ navbar }) => {
  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false)
  const environment = process.env.GATSBY_STRAPI_ENV

  return (
    <>
      {/* The actual navbar */}

      <BaseNav navbar={navbar} setMobileMenuIsShown={setMobileMenuIsShown} />

      {/* Mobile navigation menu panel */}
      {mobileMenuIsShown && (
        <MobileNavMenu navbar={navbar} closeSelf={() => setMobileMenuIsShown(false)} />
      )}
    </>
  )
}

Navbar.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes
  })
}

export default Navbar
