import React from 'react'
import './VipFooter.less'
import Image from '../image'
import CustomLink from '../custom-link'
const VipDrivingFooter = ({ footer }) => {
    console.log(footer)
    return (
        <div className="main-container p-8 flex flex-col lg:flex-row  lg:items-center">
            <div>
                {footer?.logo && footer.logo && (
                    <Image
                        media={footer.logo}
                        className="h-8 w-auto object-contain"
                    />
                )}

            </div>
            <div className="vip-footer-text">
                <p>230 Murray Street</p>
                <p>Hobart TAS 7000</p>
            </div>
            <div className="vip-footer-text">
                <p>
                    Mobile: <a href="tel:0449 845 797" className={"text-white hover:text-white"}>0449 845 797</a>
                </p>
            </div>
            <div className="vip-footer-text">
                <p>© Copyright 2022</p>
            </div>
        </div>
    )
}

export default VipDrivingFooter
