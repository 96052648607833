import React from 'react'
import Image from './image'
import PropTypes from 'prop-types'
import { linkPropTypes, mediaPropTypes } from '@/utils/types'
import CustomLink from './custom-link'
import './Footer.less'
import LeadVisaFooter from './LeadVisa/LeadVisaFooter'
import VipDrivingFooter from "./VipDrivingHobart/VipDrivingFooter";
const Footer = ({ footer }) => {
  const environment = process.env.GATSBY_STRAPI_ENV

  const Base = () => {
    return (
      <>
        <div className="main-container container flex flex-col lg:flex-row lg:justify-between">
          <div>
            {footer?.logo && footer.logo && (
              <Image media={footer.logo} className="h-8 w-auto object-contain" />
            )}
          </div>
          <nav className="flex flex-wrap flex-row lg:gap-20 items-start lg:justify-end mb-10">
            {footer?.columns.map(footerColumn => (
              <div key={footerColumn.id} className="mt-10 lg:mt-0 w-6/12 lg:w-auto">
                <p className="uppercase tracking-wide font-semibold">{footerColumn.title}</p>
                <ul className="mt-2">
                  {footerColumn.links.map(link => (
                    <li key={link.id} className="text-gray-700 py-1 px-1 -mx-1 hover:text-gray-900">
                      <CustomLink link={link}>{link.text}</CustomLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
        </div>
        <div className="small-container text-sm bg-gray-200 py-6 text-gray-700">
          <div className="container">{footer?.smallText}</div>
        </div>
      </>
    )
  }
  const renderFooter = () => {
    if (environment == 'leadvisa') {
      return <LeadVisaFooter />
    }
      if (environment == 'vipdrivinghobart') {
          return <VipDrivingFooter footer={footer} />
      }
    return <Base />
  }

  return <footer className={`pt-12 pb-12 bg-gray-100 footer-${environment}`}>{renderFooter()}</footer>
}

Footer.propTypes = {
  footer: PropTypes.shape({
    logo: mediaPropTypes.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(linkPropTypes)
      })
    ),
    smallText: PropTypes.string.isRequired
  })
}

export default Footer
