export function getStrapiMedia(url) {
  if (url == null) {
    return null;
  }

  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith("http") || url.startsWith("//")) {
    return url;
  }
  if(url.indexOf('cloudinary')>0) return  url;

  // Otherwise prepend the URL path with the Strapi URL
  return `${process.env.STRAPI_API_URL || 'https://strapi.bikramkawan.com'}${url}`;
}
