import { Link } from 'gatsby'
import Image from '../image'
import CustomLink from '../custom-link'
import { MdMenu } from 'react-icons/md'
import ButtonLink from '../button-link'
import React from 'react'
import { getButtonAppearance } from '@/utils/button'
import { Menu } from 'antd'
import { MailOutlined, CaretDownOutlined, SettingOutlined } from '@ant-design/icons'
import './LeadVisaNavBar.less'
import NAV from './Navigation'
const { SubMenu } = Menu

const LeadVisaNavBar = ({ navbar, setMobileMenuIsShown }) => {
  return (
    <>
      <nav className="st-navbar leadvisa-navbar border-gray-200 border-b-2 py-6 sm:py-2">
        <div  className="header-topbar md-hidden sm-hidden clearfix">
          <div className="container">
            <div className="row">
              <div className="col-md-12">

                <ul className="info-list fleft">
                  <li>
                    <i className="fa fa-map-marker"></i> 162 Macquarie Street, Level 1, Hobart, Tasmania, 7000 ( <a
                      href="https://goo.gl/maps/5r4gaNSwDTvLzvFLA" target="_blank">View
                    map</a> )
                  </li>
                  <li>
                    <i className="fa fa-phone"></i> <a href="tel:0414955533">(+61) 03 6135 2382</a></li>
                  <li>
                    <i className="fa fa-clock"></i> Mon to Fri 10:00 - 18:00
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
        <div className="container flex flex-row items-center justify-between">
          {/* Content aligned to the left */}
          <div className="flex flex-row items-center">
            <Link to="/">
              <Image media={navbar?.logo} className="st-logo-header h-8 w-auto object-contain" />
            </Link>
            {/* List of links on desktop */}
            <div className="sm:hidden">
              <Menu mode="horizontal">
                {NAV.map(nav => {
                  if (nav?.childrens) {
                    return (
                        <SubMenu key={nav.key} title={nav.label} >
                          {nav.childrens.map(ch => {
                            return (
                                <Menu.Item key={ch.label}>
                                  <a href={ch.link}>{ch.label}</a>
                                </Menu.Item>
                            )
                          })}
                        </SubMenu>
                    )
                  }
                  return (
                      <Menu.Item key={nav.label}>
                        <a href={nav.link}>{nav.label}</a>
                      </Menu.Item>
                  )
                })}
              </Menu>
            </div>
          </div>
          {/* Hamburger menu on mobile */}
          <button onClick={() => setMobileMenuIsShown(true)} className="p-1 block md:hidden">
            <MdMenu className="h-8 w-auto" />
          </button>
        </div>
      </nav>
    </>
  )
}

export default LeadVisaNavBar
